@import 'main.scss';
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50000000;
  pointer-events: none;
  user-select: none;
  background-color: var(--primary);

  .content {
    width: 100%;
    height: 100vh;
    position: relative;

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: auto;

      @media screen and (min-width: 1024px) {
        width: auto;
      }

      svg {
        width: 100%;
        height: auto;
      }

      svg path[data-name="Blink"] {
        animation: Blink 1.435s infinite backwards;
      }

      @keyframes Blink {
        from {
          visibility: hidden;
        }
        50% {
          visibility: hidden;
        }
        to {
          visibility: visible;
        }
      }
    }
  }
}
